/* global OwAd */
/* global overwolf */

import React from 'react';
// import windowsService from '../common/services/windows-service';

const names_to_windows = {"desktop": "main", 
                          "in_game_desktop": "ingamedesktop", 
                          "loading": "loading"}
  
export default class AdBox extends React.Component {
  constructor(props) {
    super(props);
    this.state= {
    } 
    this.owAd = null
  }

//   shouldComponentUpdate = (nextProps, nextState) => {
//     var update = false
//     if ("state" in this.props) {
//       if("data" in this.props.state) {
//         if(this.props.state.data.supporter !== nextProps.state.data.supporter){
//           if(nextProps.state.data.supporter.status === true){
//             console.log("supporter, removing ad")
//             setTimeout(() => {this.deleteAd(0)}, 1500)
//           } else {
//             if ((nextProps.state.data.supporter.type !== "Checking") && (nextProps.state.data.supporter.type !== "Overwolf_Checked")){
//               setTimeout(() => {this.createAd()}, 200)
//             }
//           }
//           update = true
//         }
//       }
//     }
//     return update
//   }

//   refreshAd = () => {
//     if (this.owAd !== null){
//       console.log("Refreshing ad")
//       this.owAd.refreshAd()
//     }
//   }

//   deleteAd = (retries) => {
//     var ad_removed = false
//     if (this.owAd !== null){
//       if (typeof window.owAdReference !== 'undefined') {
//         console.log("removing ad")
//         this.owAd.removeAd()
//         this.owAd = null
//         window.owAdReference = null;
//         ad_removed = true
//       }
//     } 
//     if (ad_removed === false) {
//         retries +=1
//         console.log("could not remove ad, retrying: ", retries)
//         if (retries < 5){
//           setTimeout(() => {
//             this.deleteAd(retries)
//           }, 2000);
//         }
//       }
    
//   }

//   removeAd = (retries) => {
//     if (this.owAd !== null){
//       this.owAd.removeAd()
//     } else {
//       retries +=1
//       if (retries < 5){
//         setTimeout(() => {
//           this.removeAd(retries)
//         }, 2000);
//       }
//     }
//   }
  
  createAd = (counter) => {
    // if ("state" in this.props) {
    //   if("data" in this.props.state) {
    //     if(this.props.state.data.supporter.status === true){
    //       return
    //     }
    //   }
    // }
    if (window['nitroAds'] !== "undefined") {
      if (navigator.userAgent.indexOf("Chrome/83.0.4103.106") !== -1){
        window['nitroAds'].createAd((this.props.ad_name), {
          "refreshLimit": 10,
          "refreshTime": 60,
          "renderVisibleOnly": true,
          "refreshVisibleOnly": true,
          "sizes": [
            [
              "300",
              "250"
            ]
          ],
          "report": {
            "enabled": true,
            "wording": "Report Ad",
            "position": "top-right"
          }
        });
      } else {
        window['nitroAds'].createAd((this.props.ad_name), {
            "format": "video-nc",
            "video": {
              // "float": "always",
              // "mobile": "compact",
              "initialDelay": 3,
              "hidePlaylist": true
            }
        });
      }
    } else {
      counter +=1
      if (counter < 20){
        setTimeout(() => {
          this.createAd(counter)
        }, 1000);
      } else {
        console.log("20 reattempts, couldn't load ad")
      }
    }
  }

//   AltTabListener (event) {
//     console.log("Alt tab detected: ", event)
//     if (event.focusChanged === true){
//       if (event.gameInfo.title === "League of Legends"){
//         //var window_name = names_to_windows[this.props.ad_name]
//         if (event.gameInfo.isInFocus === false) {
//           console.log("Minimizing, removing ad")
//           this.removeAd(0);
//           //WindowsService.MinimizeAll()
//         } else if (event.gameInfo.isInFocus === true) {
//           if ("state" in this.props) {
//             if("data" in this.props.state) {
//               if(this.props.state.data.supporter.status !== true){
//                 this.refreshAd();
//                 return
//               }
//             }
//           }
//           // WindowsService.RestoreAll()
//         }
//       }
//     }
//   }

//  onWindowStateChanged(state) {
//     console.log(`Window state changed: ${JSON.stringify(state)}`);
//     if(state) {
//       // when state changes to minimized, call removeAd()
//       if (state.window_name === names_to_windows[this.props.ad_name]){
//         if (state.window_state === "minimized") {
//           this.removeAd(0);
//         }
//         // when state changes from minimized to normal, call refreshAd()

//         else if(state.window_previous_state === "minimized" && state.window_state === "normal"){
//           if ("state" in this.props) {
//             if("data" in this.props.state) {
//               if(this.props.state.data.supporter.status !== true){
//                 this.refreshAd();
//                 return
//               }
//             }
//           }
//         }
//       }
//     }

//   }

//   openSupporterURL = () => {
//     overwolf.utils.openStore({
//       page:overwolf.utils.enums.eStorePage.SubscriptionPage
//      })
//   }

  componentDidMount = () => {
    // const script = document.createElement("script")
    // script.src = "https://s.nitropay.com/ads-941.js"
    // script.async = true;
    // document.body.appendChild(script)

    // const script2 = document.createElement("script")
    // script2.type = 'text/javascript';
    // script2.text = 'window.nitroAds=window.nitroAds||{createAd:function(){window.nitroAds.queue.push(["createAd",arguments])},addUserToken:function(){window.nitroAds.queue.push(["addUserToken",arguments])},queue:[]};'
    // document.body.appendChild(script2)
    this.createAd()
    // if (this.props.state.data.supporter.type === "Checked"){
    //   var counter = 0
    //   this.createAd(counter)
    // }
    // overwolf.windows.onStateChanged.removeListener(this.onWindowStateChanged.bind(this));
    // overwolf.windows.onStateChanged.addListener(this.onWindowStateChanged.bind(this))
    // //if ingame window listen for alt+tab
    // if (["in_game_desktop", "loading"].includes(this.props.ad_name)){
    //   overwolf.games.onGameInfoUpdated.removeListener(this.AltTabListener.bind(this));
    //   overwolf.games.onGameInfoUpdated.addListener(this.AltTabListener.bind(this));
    // }
  }

//   componentWillUnmount = () => {
//     if (this.owAd !== null){
//       this.owAd.removeAd()
//     }
//   }

  //
  render() {
    //console.log("supporter status: ", this.props.state.data.supporter)
    var ad_message = <div className="ad_message_nwmm">Ads help support development and pay for server costs</div>
    if(this.props.supporter_status === true){
      ad_message = <div className="ad_message_nwmm">Thanks for supporting New World MiniMap!</div>
    }
    return (
      <div className="ad_space_container_nwmm">
        {ad_message}
        <div className="ad_space_nwmm">
          <img src="nwmm_logo2.png" draggable = "false" className="ad_bg_nwmm"/>
          <div id={this.props.ad_name} className="ad_container_nwmm"> </div>
        </div>
      </div>
    )
  }
}