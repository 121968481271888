import React from 'react';
import ButtonTooltip from '../../components/ButtonTooltip'
import { connect } from 'react-redux'
const service_colors = {0: "#dbdbdb", 1: "#38b322", 2: "#ba6f18", 3:"#cc1f1f", 4:"#cc1f1f"}

const service_color_names = {0: "Checking", 1: "Green", 2: "Amber", 3:"Red", 4:"Red"}

const service_message = {
  0: "Checking Service Status", 
  1: "Connected to NWMM and receiving location", 
  2: "Connected to NWMM but not receiving location information", 
  3: "Cannot connect to NWMM, please start New World and NWMM, then refresh the page to try again. Click for more info",
  4: "Could not connect to Stream Group. Invalid Join Code or too many connections - please create a new code in NWMM"
}

class LocationStatus extends React.PureComponent {
  render() {
    return (
      <div className="LocationStatus">
        <ButtonTooltip title={service_message[this.props.status]} placement="top">
          <div className="EventStatusIndicator" id = "EventStatusIndicator" style={{backgroundColor: service_colors[this.props.status], boxShadow: "0px 0px 4px 2px "+service_colors[this.props.status]}}></div>
        </ButtonTooltip>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { connectionStatus } = state;
  return { status: connectionStatus };
}

export default connect(mapStateToProps)(LocationStatus);
