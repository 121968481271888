import './App.css';
import React, { Component } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import {MdOutlineLink} from 'react-icons/md'
import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { Link } from 'react-router-dom';
                

const ShareButton = styled(Button)({
  fontFamily: "Poppins",
  fontSize: 12,
  padding: '6px 20px',
  margin: '6px',
  border: '1px solid #808180',
  color: "#d6d7d5",
  lineHeight: 1.5,
  transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
  '&:hover': {
    backgroundColor: '#434241',
    borderColor: '#a6a7a6',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#434241',
    borderColor: '#a6a7a6',
  },
  '&:focus': {
    borderColor: '#a6a7a6',
    boxShadow: '0 0 0 0.2rem rgba(166, 167, 166,.5)',
    outline: "none"
  },
  '&:disabled': {
    color: "#742624",
    borderColor: '#742624',
    outline: "none"
  },
});

const CssTextField = styled(TextField)({
  fontFamily: "Poppins",
  '& label.Mui-focused': {
    color: '#c2403c',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#c2403c',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: "1px solid #808180",
    },
    '&:hover fieldset': {
      borderColor: '#c2403c',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#c2403c',
    },
  },
  '& .MuiInputBase-input': {
    color: "#eeefed",
  },
  '&  .MuiInputLabel-root': {
    color: "#d6d7d5",
  }
});


function BasicTextFields(joinText) {
  return (
    <Box
      component="form"
      sx={{
        '& > :not(style)': { width: '40ch', },
      }}
      noValidate
      autoComplete="off"
    >
      <CssTextField id="size-input" label="Your Join Code" variant="outlined" size="small" type="text"
         value={joinText}
      />
    </Box>
  );
}

class ShareLinkButton extends Component {
  constructor(props) {
		super(props);

		this.state = {
      open: false,
		}
	}


  handleTooltipClose = () => {
    this.setState({open: false});
  };

  handleTooltipOpen = () => {
    this.setState({open: true});
  };

  buttonClick=()=> {
    this.props.shareGroup()
    this.handleTooltipOpen()
  }

  render (){
    return (
      <ClickAwayListener onClickAway={this.handleTooltipClose}>
        <div>
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={this.handleTooltipClose}
            open={this.state.open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title="Join Code to Clipboard"
          >
            <ShareButton variant="outlined" startIcon={<MdOutlineLink/>} onClick={this.buttonClick}>Copy</ShareButton>
          </Tooltip>
        </div>
      </ClickAwayListener>
    )
  }

}

class Join extends Component {
  constructor(props) {
		super(props);

		this.state = {
      join_code: ""
		}
	}

  componentDidMount = () => {
    
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const join_code = params.get('code');
    console.log(join_code)
    this.setState({join_code: join_code})
  }

  shareGroup = () => {
    //add share group logic
    navigator.clipboard.writeText(this.state.join_code)
  }

 render(){
  return (
    <div className="App">
      <div className="container" style={{display: "flex", flex: "1 1 auto", flexDirection: "column", justifyContent: "space-between"}}>
        <div className ="Title">
          <img className="Logo" src="nwmm_logo2.png" alt="NWMM - New World Minimap logo"></img>
          <b>New World MiniMap - How to Join a Group</b>
        </div>
        <div className ="Row">
          1. If you don't already have New World MiniMap installed, download it on Overwolf by clicking the link below
          <a href="https://www.overwolf.com/app/New_World_MiniMap-NWMM_-_New_World_MiniMap"><img className="DownloadButton" src="getitionow.png" alt="Get NWMM on Overwolf"></img></a>
        </div>
        <div className ="Row">
          2. Load into New World and open the fullscreen map by clicking the map button on the minimap, or using the hotkey (default Alt+N)
          <img className="OpenMap" src="open_main_map2.png" alt="Open the main map"></img>
        </div>
        <div className ="Row">
          3. Open the Share Location tab, and paste either the full URL or just the Join Code below into the Join a Group box, and press join
          <div className="JoinCodeContainer">          
            <div className="ShareLocationInput">
            {BasicTextFields(this.state.join_code)}
            <ShareLinkButton shareGroup={this.shareGroup.bind(this)} />
            </div>
          </div>
          <img className="JoinCode" src="enter_join_code.png" alt="Enter the Join Code"></img>
        </div>
        <div className ="Row">
          <span>4. Now head to the <Link className = "Link" to={"/map?code="+this.state.join_code} style={{display: "inline-block"}}>Browser Map</Link> to see your live location. You can also share this code with others to see each other, or use it on another device.</span>
        </div>
        <div className ="Row">
        </div>
      </div>
      <div className="footer">
        <div className="container" style={{fontSize:"12px", padding: "2px"}}>
          &copy; {new Date().getFullYear()} NewWorldMiniMap.com New World MiniMap is not affiliated with New World or Amazon Game Studios in any way. 
        </div>
      </div>
    </div>
  );
 }
  
}

export default Join;
