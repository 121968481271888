import React, { Component } from 'react';
import {MdInfoOutline, MdOutlineChevronRight, MdOutlineExpandMore, MdMinimize, MdOutlineHorizontalRule, MdCheck, MdOutlineCheckBoxOutlineBlank, MdOutlineCheckBox, MdOutlineIndeterminateCheckBox, MdArrowDropDown, MdExpandLess, MdArrowRight, MdOutlineLens} from 'react-icons/md'
import Collapse from '@mui/material/Collapse';
import classNames from 'classnames';

const NodeImages = 
{ 
  "Chests": "supply_1",
  "Documents": "document",
  "Essences": "spirit_plant",
  "Fishing": "hotspot_secret",
  "Monsters": "wolf",
  "Ores": "iron",
  "Plants": "vegetable",
  "Woods": "wyrdwood",
  "Town": "town_board",
  "Events": "icecave",
  "Special": "glyph",
}

class FilterItem extends Component {
  toggle_filter_item = () => {
    if (!this.props.data.disabled){
      var add
      if (this.props.data.enabled === true){
        add = false
      } else {
        add = true
      }
      this.props.modify_enabled(add, [this.props.data.value])
    }
  }

  render(){

    var checkboxfill

    if(this.props.data.enabled === true) {
      checkboxfill= <span className="NodeRowCheckboxTicked"> <MdCheck style={{height: "20px", width: "20px"}}></MdCheck></span>
    }

    return (
      <div className="FilterRow" onClick={this.toggle_filter_item}>
        <div className="NodeRowTop">
          <div className="NodeRowExpandPlaceholder">
          </div>
          <span className="NodeRowCheckbox">{checkboxfill}</span>
          <img className="FilterRowImage" src={"icons/"+this.props.data.image_name+"_compass.png"}/>
          <span className="FilterRowTitle"> {this.props.data.label} </span>      
        </div>
      </div>
    );
  }
}

class Node extends Component {
  constructor(props) {
		super(props);

		this.state = {
      expanded: false
		}
	}

  setExpanded = () => {
    this.setState({expanded: !this.state.expanded})
  }

  toggle_node = () => {
    if (!(this.props.disabled || this.props.disabled2)){
      var add
      if (this.props.enabled === "full"){
        add = false
      } else {
        add = true
      }
      var children = []
      this.props.children.forEach(child => {
        if("children" in child){
          child.children.forEach(child2 => {
            if(child2.enabled !== add){
              children.push(child2.value)
            }
          })
        } else {
          if(child.enabled !== add){
            children.push(child.value)
          }
        }
      })
      this.props.modify_enabled(add, children)
    }
  }
  

  render(){

    var checkboxfill

    if(this.props.enabled==="full"){
      checkboxfill= <span className="NodeRowCheckboxTicked"> <MdCheck style={{height: "20px", width: "20px"}}></MdCheck></span>
    } 
    if(this.props.enabled==="partial"){
      checkboxfill= <span className="NodeRowCheckboxPartial"> <MdMinimize style={{height: "20px", width: "20px"}}></MdMinimize></span>
    }
    
    var expandcontent
    if(this.state.expanded){
      expandcontent = <MdOutlineExpandMore style={{height: "22px", width: "22px"}}/>
    } else {
      expandcontent = <MdOutlineChevronRight style={{height: "22px", width: "22px"}}/>
    }

    var children = []
    this.props.children.forEach((child, index) => {
      if ("children" in child){
        var enabled = 0
        child.children.forEach(child2 => {
          if (this.props.enabled_values.includes(child2.value)){
            enabled += 1
            child2.enabled = true
          } else {
            child2.enabled = false
          }
        })
        var enabled_string = "partial"
        if(enabled === 0) {
          enabled_string = "none"
        } else if (enabled === child.children.length) {
          enabled_string = "full"
        } 
        children.push(<Node label = {child.label} key={child.label} img={child.img} disabled2 = {this.props.disabled} enabled = {enabled_string} children={child.children} modify_enabled={this.props.modify_enabled.bind(this)}></Node>)
      } else {
        children.push(<FilterItem key={index} data={child} modify_enabled={this.props.modify_enabled.bind(this)}/>)
      }
    })

    var image_src =""
    if("img" in this.props){
      image_src = this.props.img
    } else {
      image_src = NodeImages[this.props.label]
    }

    return (
      <div className={classNames("NodeRowContainer", { "NodeRowDisabled": this.props.disabled })}>
        <div className="NodeRow">
          <div className="NodeRowExpand" onClick={this.setExpanded}>
            {expandcontent}
          </div>
          <div className="NodeRowTop" onClick={this.toggle_node}>
            <span className="NodeRowCheckbox">{checkboxfill}</span>
            <img className="FilterRowImage" src={"icons/"+image_src+"_compass.png"}/>
            <span className="NodeRowTitle"> {this.props.label} </span>      
          </div>
        </div>
        <Collapse in={this.state.expanded}>
          <div className="FilterRowContainer">
            {children}
          </div>
        </Collapse>
      </div>
    );
  }
}

class Filters extends Component {
  constructor(props) {
		super(props);

		this.state = {
      enabled : [],
		}
	}

  modify_enabled = (add, list) => {
    if (add === true){
      var new_enabled = this.state.enabled.concat(list)
    } else {
      var new_enabled = this.state.enabled.filter( ( el ) => !list.includes( el ) );
    }
    this.setState({enabled: new_enabled})
    this.props.updateEnabled(new_enabled)
    localStorage.setItem("NWWM_WebMarkers", JSON.stringify(new_enabled))
  }

  componentDidMount = () => {
    var setEnabled = false
    if ("enabled" in this.props){  
      if ((this.props.enabled !== null) && (this.props.enabled.length !== 0)){
        this.setState({enabled: this.props.enabled})
        setEnabled = true
      } 
    }
    if (setEnabled === false) {
      var old_markers = localStorage.getItem("NWWM_WebMarkers")
      if (old_markers !== null){
        var enabled_list = JSON.parse(old_markers)
      } else {
        var enabled_list = []
        this.props.Nodes.forEach(node => {
          // if (node.value == "town"){
            node.children.forEach(child => {
              if("children" in child){
                child["children"].forEach(item2 => {
                  enabled_list.push(item2.value)
                })
              } else {
                enabled_list.push(child.value)
              }
              
            })
          // }
        })
      }
      this.setState({enabled: enabled_list})
      this.props.updateEnabled(enabled_list)
    }
  }

 render(){
  var node_list = []
  this.props.Nodes.forEach(node => {
    var enabled = 0
    var total = 0
    // if (!["chests", "documents", "woods"].includes(node.value)){
      var disabled = false
      if (node.value == "town"){
        disabled = false
      }
      if (node.value == "events"){
        return
      }
      node.children.forEach(child => {
        if("children" in child){
          child["children"].forEach(child2 => {
            total += 1
            if (this.state.enabled.includes(child2.value)){
              enabled += 1
              child2.enabled = true
            } else {
              child2.enabled = false
            }
            child2.disabled = disabled

          })
        } else {
          total += 1
          if (this.state.enabled.includes(child.value)){
            enabled += 1
            child.enabled = true
          } else {
            child.enabled = false
          }
          child.disabled = disabled
        }
      
      })
      var enabled_string = "partial"
      if(enabled === 0) {
        enabled_string = "none"
      } else if (enabled === total) {
        enabled_string = "full"
      } 
      node_list.push(<Node label = {node.label} key={node.label} disabled={disabled} enabled = {enabled_string} enabled_values={this.state.enabled} children={node.children} modify_enabled={this.modify_enabled.bind(this)}></Node>)
    // }
  })


  return (
      <div className="FilterContainer" >   
      {/* <div className="ShareLocationInfoRow" style={{flexDirection: "row", textAlign:"center"}}> <span><MdInfoOutline style={{fontSize: "18px", marginRight:"5px"}}/>Filters have been disabled to comply with Amazon ToS. Compass data will be integrated ASAP!</span></div>     */}
            {node_list}
      </div>
  );
 }
  
}

export default Filters;
