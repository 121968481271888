import React, { Component } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import {MdOutlineLink, MdOutlineClose, MdPerson} from 'react-icons/md'
import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { BsInfoCircle } from 'react-icons/bs'
                

const ShareButton = styled(Button)({
  fontFamily: "Poppins",
  fontSize: 12,
  padding: '6px 20px',
  margin: '6px',
  border: '1px solid #808180',
  color: "#d6d7d5",
  lineHeight: 1.5,
  transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
  '&:hover': {
    backgroundColor: '#434241',
    borderColor: '#a6a7a6',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#434241',
    borderColor: '#a6a7a6',
  },
  '&:focus': {
    borderColor: '#a6a7a6',
    boxShadow: '0 0 0 0.2rem rgba(166, 167, 166,.5)',
    outline: "none"
  },
  '&:disabled': {
    color: "#742624",
    borderColor: '#742624',
    outline: "none"
  },
});

const LocationButton = styled(Button)({
  fontFamily: "Poppins",
  fontSize: 12,
  padding: '6px 20px',
  margin: '6px',
  border: '1px solid #c2403c',
  color: "#c2403c",
  lineHeight: 1.5,
  transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
  '&:hover': {
    backgroundColor: '#434241',
    borderColor: '#c2403c',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#434241',
    borderColor: '#c2403c',
  },
  '&:focus': {
    borderColor: '#c2403c',
    boxShadow: '0 0 0 0.2rem rgba(194,64,60,.5)',
    outline: "none"
  },
  '&:disabled': {
    color: "#742624",
    borderColor: '#742624',
    outline: "none"
  },
});

const CssTextField = styled(TextField)({
  fontFamily: "Poppins",
  '& label.Mui-focused': {
    color: '#c2403c',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#c2403c',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: "1px solid #808180",
    },
    '&:hover fieldset': {
      borderColor: '#c2403c',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#c2403c',
    },
  },
  '& .MuiInputBase-input': {
    color: "#eeefed",
  },
  '&  .MuiInputLabel-root': {
    color: "#d6d7d5",
  }
});


function BasicTextFields(joinText, handleJoinText) {
  return (
    <Box
      component="form"
      sx={{
        '& > :not(style)': { width: '20ch', },
      }}
      noValidate
      autoComplete="off"
    >
      <CssTextField id="size-input" label="Join Code" variant="outlined" size="small" type="text"
         value={joinText}
         onChange={handleJoinText}
      />
    </Box>
  );
}

class ShareLinkButton extends Component {
  constructor(props) {
		super(props);

		this.state = {
      open: false,
		}
	}


  handleTooltipClose = () => {
    this.setState({open: false});
  };

  handleTooltipOpen = () => {
    this.setState({open: true});
  };

  buttonClick=()=> {
    this.props.shareGroup()
    this.handleTooltipOpen()
  }

  render (){
    return (
      <ClickAwayListener onClickAway={this.handleTooltipClose}>
        <div>
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={this.handleTooltipClose}
            open={this.state.open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title="Share Link Copied to Clipboard"
          >
            <ShareButton variant="outlined" startIcon={<MdOutlineLink/>} onClick={this.buttonClick}>Share</ShareButton>
          </Tooltip>
        </div>
      </ClickAwayListener>
    )
  }

}


class ShareLocation extends Component {
  constructor(props) {
		super(props);

		this.state = {
      in_group: false,
      joinText: "",
		}
	}

  handleJoinText = (event) => {
    this.setState({joinText: event.target.value})
  }

  createGroup = () => {
    //add create group logic
    this.setState({in_group: true})
  }

  joinGroup = () => {
    //add join group logic
    this.setState({in_group: true})
  }

  leaveGroup = () => {
    //add leave group logic
    this.setState({in_group: false})
  }

  shareGroup = () => {
    //add share group logic
    navigator.clipboard.writeText("https://newworldminimap.com/join?code=placeholder")
  }

 render(){
  var sharelocation_content

  if(this.state.in_group == true){
    sharelocation_content = 
    <React.Fragment>
      <div className="ShareLocationRow">
          Group Controls
          <div className="ShareLocationInput">
            <ShareLinkButton shareGroup={this.shareGroup.bind(this)} />
            <LocationButton variant="outlined" startIcon={<MdOutlineClose/>} onClick={this.leaveGroup}>Leave</LocationButton>
          </div>
        </div>
        <div className="ShareLocationRow">
          Group Members
          <div className="ShareLocationPlayerList">
            <div className="ShareLocationPlayer">
              <span><MdPerson/> Player 1</span>
              <span>[x1, y1]</span>
            </div>
            <div className="ShareLocationPlayer">
              <span><MdPerson/> Player 2</span>
              <span>[x2, y2]</span>
            </div>
          </div>
        </div>
      </React.Fragment>
  } else {
    sharelocation_content = 
    <React.Fragment>
      <div className="ShareLocationInfoRow">
        <span><BsInfoCircle style={{fontSize: "14px", marginBottom: "2px"}}/> Group size is limited to 5</span><span>Location Sharing is in beta and the service may go down </span>
      </div>
      <div className="ShareLocationRow">
          Create a Group
          <div className="ShareLocationInput">
            <LocationButton variant="outlined" onClick={this.createGroup}>Create</LocationButton>
          </div>
        </div>
        <div className="ShareLocationRow">
          Join a Group
          <div className="ShareLocationInput">
          {BasicTextFields(this.state.joinText, this.handleJoinText.bind(this))}
          <LocationButton variant="outlined" disabled={(this.state.joinText === "")} onClick={this.joinGroup}>Join</LocationButton>
          </div>
        </div>
      </React.Fragment>
  }



  return (
    <div className="ShareLocationContainer">
      {sharelocation_content}
    </div>
  );
 }
  
}

export default ShareLocation;
