import { createSlice } from '@reduxjs/toolkit'

const trackedPlayerSlice = createSlice({
    name: 'trackedPlayer',
    initialState: {
        pinned: false,
        trackedPlayerId: '',
    },
    reducers: {
        trackPlayer: (state, action) => {
            state.pinned = true
            state.trackedPlayerId = action.payload
        },
        togglePinned: (state, action) => {
            state.pinned = !state.pinned
        },
        unpin: (state, action) => {
            state.pinned = false
        }
    },
})

export default trackedPlayerSlice
