import { createSlice } from '@reduxjs/toolkit'
import groupCode from './groupCode'

const connectedPlayersSlice = createSlice({
    name: 'connectedPlayers',
    initialState: {},
    reducers: {
        updatePlayerLocation: (state, action) => {
            let { playerId, playerName, position } = action.payload
            // Insert or update
            state[playerId] = { playerName, position }
        },
        removePlayer: (state, action) => {
            let { id } = action.payload
            delete state[id]
        },
    },
    extraReducers: {
        // Clear connectedPlayers if a group is joined or left
        [groupCode.actions.joinGroup]: (state, action) => {
            return {}
        },
        [groupCode.actions.leaveGroup]: (state, action) => {
            return {}
        },
    }
})

export default connectedPlayersSlice
