import './App.css';
import React, { Component } from 'react';
import Main from './Main.js'
import NoMatch from './NoMatch.js'
import Fullscreen from './Fullscreen.js'
import Secret from './Secret.js'
import Map from './Map.js'
import Join from './Join.js'
import OverwolfHelp from './OverwolfHelp.js'
import Privacy from './Privacy.js'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

class App extends Component {
  constructor(props) {
		super(props);

		this.state = {
		}
	}

 render(){
  return (  
    <Router>
      <Switch>
        <Route path="/fullscreen">
          <Fullscreen />
        </Route>
        {/* <Route path="/secret">
          <Secret />
        </Route> */}
        <Route path="/map">
          <Map />
        </Route>
        <Route path="/join">
          <Join />
        </Route>
        <Route path="/help">
          <OverwolfHelp/>
        </Route>
        <Route path="/privacy">
          <Privacy/>
        </Route>
        <Route path="/">
          <Main />
        </Route>
        <Route path="*">
          <NoMatch />
        </Route>
      </Switch>
    </Router>
  );
 }
  
}

export default App;
