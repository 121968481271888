import './App.css';
import React, { Component } from 'react';
import {FaDiscord} from 'react-icons/fa'
import {IoClose} from 'react-icons/io5'
import {MdExpandMore, MdOutlineHelp} from 'react-icons/md'
import Slider from '@mui/material/Slider';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import CheckboxTree from 'react-checkbox-tree';
import {MdOutlineArrowBackIosNew, MdOutlineArrowForwardIos, MdOutlineWarningAmber, MdOutlineCheckBoxOutlineBlank, MdOutlineCheckBox, MdOutlineIndeterminateCheckBox, MdArrowDropDown, MdExpandLess, MdArrowRight, MdOutlineLens} from 'react-icons/md'
import { Scrollbars } from 'react-custom-scrollbars-2';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Filters from './components/Filters'
import Button from '@mui/material/Button';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';


const SettingsTabs = styled(Tabs)({
  '& .MuiTabs-indicator': {
    backgroundColor: '#c2403c',
  },
});

const SettingsTab = styled((props) => <Tab {...props} />)(({ theme }) => ({
  padding: '6px 20px',
  margin: '6px',
  fontFamily: "Poppins",
  color: "#d6d7d5",
  fontSize: 12,
  '&:hover': {
    color: '#eeefed',
    opacity: 1,
    outline: "none",
  },
  '&.Mui-selected': {
    color: '#eeefed',
    borderColor: '#c2403c',
    outline: "none",
  },
  '&.Mui-focusVisible': {
    borderColor: '#c2403c',
    // backgroundColor: '#d1eaff',
    outline: "none",
  },
}));

const AutoFollowMarks = [
  {
    value: 0,
    label: 'Off Screen',
  },
  {
    value: 0.1,
    label: '',
  },
  {
    value: 0.2,
    label: '',
  },
  {
    value: 0.3,
    label: '',
  },
  {
    value: 0.4,
    label: '',
  },
  {
    value: 0.5,
    label: 'Always',
  }
];

function AutoFollowSlider(AutoFollowChange, Frequency) {

  return (
      <Slider
        id = "AutoFollowSlider"
        key = "AutoFollowSlider"
        aria-label="AutoFollow"
        defaultValue={Frequency}
        step={null}
        marks={AutoFollowMarks}
        min={0}
        max={0.5}
        sx={{
          color: '#c2403c',
          '& .MuiSlider-thumb': {
            '&:hover, &.Mui-focusVisible': {
              boxShadow: `0px 0px 0px 8px rgba(194, 64, 60, 0.19)`,
            },
            '&.Mui-active': {
              boxShadow: `0px 0px 0px 14px rgba(194, 64, 60, 0.19)`,
            },
          },
          '& .MuiSlider-markLabel': {
            color: "#d6d7d5",
            fontSize: "10px",
          }
        }}
        onChangeCommitted={(e, value) => {AutoFollowChange(value)}}
      />
  );
}

const QuitButton = styled(Button)({
  fontFamily: "Poppins",
  fontSize: 12,
  padding: '6px 20px',
  margin: '6px',
  border: '1px solid #c2403c',
  color: "#c2403c",
  lineHeight: 1.5,
  transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
  '&:hover': {
    backgroundColor: '#434241',
    borderColor: '#c2403c',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#434241',
    borderColor: '#c2403c',
  },
  '&:focus': {
    borderColor: '#c2403c',
    boxShadow: '0 0 0 0.2rem rgba(194,64,60,.5)',
    outline: "none"
  },
  '&:disabled': {
    color: "#742624",
    borderColor: '#742624',
    outline: "none"
  },
});

const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#c2403c',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#c2403c',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#333231',
    },
    '&:hover fieldset': {
      borderColor: '#c2403c',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#c2403c',
    },
  },
  '& .MuiInputBase-input': {
    color: "#eeefed",
  },
  '&  .MuiInputLabel-root': {
    color: "#d6d7d5",
  }
});

const OpacityMarks = [
  {
    value: 0.1,
    label: '0.1',
  },
  {
    value: 0.2,
    label: '',
  },
  {
    value: 0.3,
    label: '',
  },
  {
    value: 0.4,
    label: '0.4',
  },
  {
    value: 0.5,
    label: '',
  },
  {
    value: 0.6,
    label: '',
  },
  {
    value: 0.7,
    label: '0.7',
  },
  {
    value: 0.8,
    label: '',
  },
  {
    value: 0.9,
    label: '',
  },
  {
    value: 1.0,
    label: '1.0',
  },
];

const ZoomMarks = [
  {
    value: 2,
    label: 'Zoomed In',
  },
  {
    value: 3,
    label: '',
  },
  {
    value: 4,
    label: '',
  },
  {
    value: 5,
    label: '',
  },
  {
    value: 6,
    label: '',
  },
  {
    value: 7,
    label: '',
  },
  {
    value: 8,
    label: 'Zoomed Out',
  },
];

const SizeMarks = [
  {
    value: 200,
    label: 'Small',
  },
  {
    value: 250,
    label: 'Medium',
  },
  {
    value: 300,
    label: 'Large',
  },
  {
    value: 350,
    label: 'Custom',
  },
];

function BasicTextFields(keyPress) {
  return (
    <Box
      component="form"
      sx={{
        '& > :not(style)': { width: '20ch', },
      }}
      noValidate
      autoComplete="off"
    >
      <CssTextField id="size-input" label="pixels" variant="outlined" size="small" type="number"
       onKeyPress={(ev) => {
        console.log(`Pressed keyCode ${ev.key}`);
        if (ev.key === 'Enter') {
          keyPress(ev)
          ev.preventDefault();
        }}}/>
    </Box>
  );
}

function SizeSlider(SizeChange, size) {
  if (![250, 300, 200].includes(size)){
    size = 350
  }

  return (
      <Slider
        id = "SizeSlider"
        key = "SizeSlider"
        aria-label="Restricted values"
        defaultValue={size}
        step={null}
        marks={SizeMarks}
        min={200}
        max={350}
        sx={{
          color: '#c2403c',
          '& .MuiSlider-thumb': {
            '&:hover, &.Mui-focusVisible': {
              boxShadow: `0px 0px 0px 8px rgba(194, 64, 60, 0.19)`,
            },
            '&.Mui-active': {
              boxShadow: `0px 0px 0px 14px rgba(194, 64, 60, 0.19)`,
            },
          },
          '& .MuiSlider-markLabel': {
            color: "#d6d7d5",
            fontSize: "10px",
          }
        }}
        onChangeCommitted={(e, value) => {SizeChange(value)}}
      />
  );
}

function OpacitySlider(updateOpacity, opacity) {

  return (
      <Slider
        aria-label="Opacity"
        defaultValue={opacity}
        step={null}
        marks={OpacityMarks}
        min={0}
        max={1}
        sx={{
          color: '#c2403c',
          '& .MuiSlider-thumb': {
            '&:hover, &.Mui-focusVisible': {
              boxShadow: `0px 0px 0px 8px rgba(194, 64, 60, 0.19)`,
            },
            '&.Mui-active': {
              boxShadow: `0px 0px 0px 14px rgba(194, 64, 60, 0.19)`,
            },
          },
          '& .MuiSlider-markLabel': {
            color: "#d6d7d5",
            fontSize: "10px",
          }
        }}
        onChangeCommitted={(e, value) => {updateOpacity(value)}}
      />
  );
}

function ZoomSlider(name, zoomStart, ZoomChange) {
  return (
      <Slider
        aria-label="Restricted values"
        defaultValue={zoomStart}
        step={null}
        marks={ZoomMarks}
        min={2}
        max={8}
        sx={{
          color: '#c2403c',
          '& .MuiRadio-root': {
            color: "#c2403c !important",
            '&:hover, &.Mui-focusVisible': {
            boxShadow: `0px 0px 0px 4px rgba(194, 64, 60, 0.19)`,

            },
            '& .Mui-active': {
            boxShadow: `0px 0px 0px 6px rgba(194, 64, 60, 0.19)`,
            color: "#c2403c",
            }
          },
          '& .MuiTypography-root': {
            color: "#d6d7d5",
            fontSize: "12px",
          },
          '& .Mui-checked': {
            color: "#c2403c !important",
          }
        }}
        onChangeCommitted={(e, value) => {ZoomChange(value, name)}}
      />
  );
}

// /MdOutlineCheckBoxOutlineBlank, MdOutlineCheckBox, MdOutlineIndeterminateCheckBox

class MultiSelect extends React.Component {
  state = {
      checked: [],
      expanded: [],
  };

  componentDidMount = () => {
    this.setState({checked: this.props.enabled})
  }

  update_checked = (checked) => {
    this.setState({checked: checked})
    this.props.updateEnabled(checked)
  }

  render() {
    // console.log(checked)
      return (
          <CheckboxTree
              nodes={this.props.Nodes}
              checked={this.state.checked}
              expanded={this.state.expanded}
              onCheck={checked => this.update_checked(checked)}
              onExpand={expanded => this.setState({ expanded })}
              icons={{
                check: <MdOutlineCheckBox className="rct-icon rct-icon-check" />,
                uncheck: <MdOutlineCheckBoxOutlineBlank className="rct-icon rct-icon-uncheck" />,
                halfCheck: <MdOutlineIndeterminateCheckBox className="rct-icon rct-icon-half-check" />,
                expandClose: <MdArrowRight className="rct-icon rct-icon-expand-close"/>,
                expandOpen: <MdArrowDropDown className="rct-icon rct-icon-expand-open" />,
                expandAll: <MdExpandMore className="rct-icon rct-icon-expand-all" />,
                collapseAll: <MdExpandLess className="rct-icon rct-icon-collapse-all" />,
                // parentClose: <MdArrowRight className="rct-icon rct-icon-parent-close" />,
                // parentOpen: <MdArrowDropDown className="rct-icon rct-icon-parent-open"  />,
                leaf: <MdOutlineLens className="rct-icon rct-icon-leaf-close" />
            }}
          />
      );
  }
}

class Settings extends Component {
  constructor(props) {
		super(props);

		this.state = {
      TownZoom: 2,
      WorldZoom: 4,
      CurrentSize: 250,
      toggle: false,
      custom_size: false,
      current_tab: "minimap"
		}
	}

  componentDidMount = () => {
    if (this.props.second_screen === true){
      this.setState({current_tab: "mainmap"})
    }
    if (window['__cmp']) {
      window['__cmp']('addConsentLink');
    }
  }

  SizeChange = (value) => {
    // this.setState({CurrentSize: value})
    if (value < 350){
      this.props.updateSize(value)
      if(this.state.custom_size == true){
        this.setState({custom_size: false})
      }
    } else {
      if(this.state.custom_size == false){
        this.setState({custom_size: true})
      }
    }
  }

  AutoFollowChange = (value) => {
    // this.setState({CurrentSize: value})
    this.props.updateAutoFollow(value)
    localStorage.setItem("NWMM_AutoFollow", value)
    console.log(value)
  }

  CustomSizeEnter = (e) => {
    console.log(e)
    if(e.key === "Enter"){
      console.log('value', e.target.value);
      this.props.updateSize(e.target.value)
      // put the login here
    }
  }
  updateEnabled = (e) => {
    console.log(e)
  }

  ZoomChange = (value, type) => {
    if (type=="Town"){
      this.setState({TownZoom: value})
    } else {
        this.setState({WorldZoom: value})
    }
  }

  togglemarkers=() => {
    this.setState({toggle : !this.state.toggle})
  }

  onCloseClicked = () => {
    this.props.toggleSettings()
  }

  openUrl = (url) => {
    window.open(url)
  }

  handleTabChange = (event, value) => {
    this.setState({current_tab: value}, () => {
      if (window['NitroPayCCPA']) {
        window['NitroPayCCPA'].init()
      }
      if (window['__cmp']) {
        window['__cmp']('addConsentLink');
    }
    })
  }

  handleShapeChange = (event) => {
    this.props.toggleShape(event.target.value)
  }

  handleAnimatingChange = (event) => {
    this.props.updateAnimating(event.target.value)
    localStorage.setItem("NWMM_Animating", event.target.value)
  }

 render(){
   var settingsOverlayStyle={}
   if(!this.state.toggle){
    settingsOverlayStyle = {display: "none"}
   }
   var custom_size_row
   if (this.state.custom_size){
    custom_size_row =               
    <div className="settingsRow">
      <span>Custom Size</span>
      <span className="SizeBox">{BasicTextFields(this.CustomSizeEnter)}</span>
    </div>
   }

   var minimap_settings =           
   <div className="settingsSection">
      <div className="settingsHeader">
        Minimap Settings
      </div>
      <div className="settingsRow">
        <span>Size</span>
        <span className="sliderBox">{SizeSlider(this.SizeChange, this.props.size)}</span>
      </div>
      {custom_size_row}
      <div className="settingsRow">
        <span>Opacity</span>
        <span className="sliderBox">{OpacitySlider(this.props.updateOpacity, this.props.opacity)}</span>
      </div>
      <div className="settingsRow">
        <span>Shape</span>
        <span style={{fontSize: "80%"}}>
          <FormControl component="fieldset">
            <RadioGroup row aria-label="shape" name="row-radio-buttons-group" value={this.props.circle} onChange={this.handleShapeChange} 
            sx={{
              color: '#c2403c',
              '& .MuiRadio-root': {
                color: "#c2403c !important",
                '&:hover, &.Mui-focusVisible': {
                boxShadow: `0px 0px 0px 4px rgba(194, 64, 60, 0.19)`,
    
                },
                '& .Mui-active': {
                boxShadow: `0px 0px 0px 6px rgba(194, 64, 60, 0.19)`,
                color: "#c2403c",
                }
              },
              '& .MuiTypography-root': {
                color: "#d6d7d5",
                fontSize: "12px",
              },
              '& .Mui-checked': {
                color: "#c2403c !important",
              }
            }}>
              <FormControlLabel value={true} control={<Radio />} label="Circle" />
              <FormControlLabel value={false} control={<Radio />} label="Square" />
            </RadioGroup>
          </FormControl>
        </span>
      </div>
     </div>

var main_map_settings =           
<div className="settingsSection">
   <div className="settingsHeader">
     Full Screen Map Settings
   </div>
   <div className="settingsRow">
     <span>Auto Follow Frequency</span>
     <span className="sliderBox">{AutoFollowSlider(this.AutoFollowChange, this.props.AutoFollow)}</span>
   </div>
   <div className="settingsRow">
     <div className="ShareLocationInfoRow" style={{flexDirection: "row"}}> <span><MdOutlineWarningAmber style={{fontSize: "18px"}} /> More frequent Auto Follow will consume high amounts of CPU and may cause framerate issues</span></div>
   </div>
   <div className="settingsRow">
        <span>Animation</span>
        <span style={{fontSize: "80%"}}>
          <FormControl component="fieldset">
            <RadioGroup row aria-label="shape" name="row-radio-buttons-group" value={this.props.animating} onChange={this.handleAnimatingChange} 
            sx={{
              color: '#c2403c',
              '& .MuiRadio-root': {
                color: "#c2403c !important",
                '&:hover, &.Mui-focusVisible': {
                boxShadow: `0px 0px 0px 4px rgba(194, 64, 60, 0.19)`,
    
                },
                '& .Mui-active': {
                boxShadow: `0px 0px 0px 6px rgba(194, 64, 60, 0.19)`,
                color: "#c2403c",
                }
              },
              '& .MuiTypography-root': {
                color: "#d6d7d5",
                fontSize: "12px",
              },
              '& .Mui-checked': {
                color: "#c2403c !important",
              }
            }}>
              <FormControlLabel value={true} control={<Radio />} label="Enabled" />
              <FormControlLabel value={false} control={<Radio />} label="Disabled" />
            </RadioGroup>
          </FormControl>
        </span>
      </div>
      <div className="settingsRow">
      <div className="ShareLocationInfoRow" style={{flexDirection: "row"}}> <span><MdOutlineWarningAmber style={{fontSize: "18px"}} /> Disabling animation can also help with CPU usage and framerate issues</span></div>
    </div>
  </div>

  var hotkey_settings = 
    <div className="settingsSection">
      <div className="settingsHeader">
        Hotkeys <span className="settingsSubtitle"> - Hotkeys will not work in the demo</span>
      </div>
      <div className="settingsRow">
        <span>Show/Hide Minimap</span>
        <span className="settingsHotkey">Alt+M</span>
      </div>
      <div className="settingsRow">
        <span>Show/Hide Main Map</span>
        <span  className="settingsHotkey">Alt+N</span>
      </div>
      <div className="settingsRow">
        <span>Zoom in</span>
        <span  className="settingsHotkey">Alt+I</span>
      </div>
      <div className="settingsRow">
        <span>Zoom out</span>
        <span  className="settingsHotkey">Alt+O</span>
      </div>
    </div>

    var about = 
      <div className="settingsSection">
        <div className="settingsHeader">
          About
        </div>
        <div className="settingsAbout">
          <div className="settingsAboutRow">New World MiniMap (NWMM) is a project from two friends to help stop us getting lost in cities and make resource gathering while questing more efficient.</div>
          <div className="settingsAboutRow">Map data comes with permission from www.newworld-map.com - please <span className="settingsHotkey">check them out</span> and contribute to mapping New World.</div>
          <div className="settingsAboutRow">Any issues, want to give us feedback or suggest new features? Join our <span className="settingsHotkey">Discord Community</span> and send us a message.</div>
          <div className="settingsAboutRow">&copy; {new Date().getFullYear()} NewWorldMiniMap.com New World MiniMap is not affiliated with New World or Amazon Game Studios in any way. </div>
        </div>
        <div className="settingsSection">
          <div className="settingsHeader"> Privacy </div>
          <div className="settingsAbout">
            Your privacy is important - you can check out our privacy policy <a href = "/privacy" style={{color:"#c2403c"}}>here</a>
          <div className="settingsRowCentered">
            <div id="ncmp-consent-link"></div>
          </div>
          <div className="settingsRowCentered" style={{color:"#c2403c"}}>
            <span data-ccpa-link="1" ></span>
          </div>
            

          </div>
        </div>
        <div className="settingsSection">
          <div className="settingsHeader"> Help </div>
          <div className="settingsAbout">
            If something isn't working or you have a question, check out our <a href = "/help" style={{color:"#c2403c"}}>help page</a>
          </div>
        </div>
        
        </div>

    var filters = 
    <div style={{flex: "1 1 0", width: "100%", minHeight: 0}}>
      <Scrollbars >
        <div className="settingsHeader">
          Filters
        </div>
        <Filters Nodes={this.props.Nodes} updateEnabled={this.props.updateEnabled} enabled={this.props.enabled}/>
      </Scrollbars>
    </div>

    var tab_content = {
      "minimap": minimap_settings,
      "mainmap": main_map_settings,
      "filters": filters,
      "hotkeys": hotkey_settings,
      "about": about,
    }

    var tab_list = []
    if (this.props.second_screen == true){
      tab_list = [
        <SettingsTab value="mainmap" label="Main Map" />,
        <SettingsTab value="about" label="About" />
      ]
    } else {
      tab_list = [
        <SettingsTab value="minimap" label="Mini Map" />,
        <SettingsTab value="mainmap" label="Main Map" />,
        <SettingsTab value="filters" label="Filters" />,
        <SettingsTab value="hotkeys" label="Hotkeys" />,
        <SettingsTab value="about" label="About" />
      ]
    }


  return (
      <div className="SettingsContainer" >
        <div className="settings" >
          <header className="app-header">
            <div className="app-icon">
              <img src="IconMouseNormal.png" style={{height: 30, width: 30}} />
              <span style={{fontSize: "18px"}}>Settings</span>
            </div>
            <div className="window-controls-group">
            <button className="icon window-control discord" id="discordButton" onClick={() => {this.openUrl("https://discord.gg/VhH7mu2SXt")}} style={{fontSize: "18px"}}>
                <FaDiscord />
              </button>
              <button className="icon window-control help" id="helpButton" onClick={() => {this.openUrl("https://discord.gg/BRTaNB5hvH")}} style={{fontSize: "20px"}}>
                <MdOutlineHelp/>
              </button>
              <button className="icon window-control window-control-close" id="closeButton" onClick={this.onCloseClicked}>
                <IoClose/>
              </button>
            </div>
          </header>
          
          <SettingsTabs
            value={this.state.current_tab}
            onChange={this.handleTabChange}
            variant="fullWidth"
          >
            {tab_list}
          </SettingsTabs>
          <div className="settingsContent">
              {/* <div className="settingsRow">
                <span>Open World Default Zoom</span>
                <span className="sliderBox">{ZoomSlider("World", 4, this.ZoomChange)}</span>
              </div>
              <div className="settingsRow">
                <span>In Town Default Zoom</span>
                <span  className="sliderBox">{ZoomSlider("Town", 2, this.ZoomChange)}</span>
              </div> */}
            {tab_content[this.state.current_tab]}

            <div className="settingsRowCentered">
              <QuitButton> Quit App </QuitButton>
            </div> 
        </div>
      </div>
    </div>
  );
 }
  
}

export default Settings;
