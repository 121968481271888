import React from 'react';
import axios from 'axios';
import ButtonTooltip from './ButtonTooltip'

const service_colors = {0: "#dbdbdb", 1: "#38b322", 2: "#ba6f18", 3:"#cc1f1f"}

const service_color_names = {0: "Checking", 1: "Green", 2: "Amber", 3:"Red"}

const service_message = {0: "Checking Service Status", 
1: "Everything working properly", 
2: "Some In-game events are down, certain features may not work", 
3: "In-game events are down, position tracking & map data will not be available. We are aware and working to get it back up in the next 24 hours"}

// class EventsTooltip extends React.Component {
//     constructor(props) {
//       super(props);
//       this.handleClick = this.handleClick.bind(this);
//       this.toggle = this.toggle.bind(this);
//       this.state = {
//         hideControl: false,
//         isOpen: false,
//       };
//     }
  
//     handleClick() {
//       this.setState({
//         hideControl: true,
//       });
//     }
  
//     toggle() {
//       this.setState({ isOpen: !this.state.isOpen });
//     }
  
  
//     render() {
//       return (
//         <>
  
//           {!this.state.hideControl
//             && (
//             <Tooltip isOpen={this.state.isOpen} toggle={this.toggle} placement="top" target={this.props.target} className="tooltip-right" style={{ backgroundColor: '#212529', padding: 8 }}>
//                 <div className="tooltip-header"><b>Service Status: {service_color_names[this.props.status]}</b></div>
//                 <div className="tooltip-description" style={{fontSize: "90%", lineHeight: "1.6"}}>{service_message[this.props.status]}</div>
//             </Tooltip>
//             )}
  
//         </>
//       );
//     }
//   }

export default class EventStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state= {
      status: 0,
      message: "fetching events status",
      details: []
    } 
  }

  componentDidMount = () => {
    axios.get("https://game-events-status.overwolf.com/21816_prod.json").then(response => {
      if ("state" in response.data) {
        this.setState({status: response.data.state})
      }
      if ("features" in response.data) {
        var bad_events = []
        response.data.features.forEach(feature => {
          if (feature.state > 1){
            bad_events.push(feature)
          }
        })
        if (bad_events.length > 0){
          this.setState({details: bad_events})
        }
      }
    })
  }

  //
  render() {

    return (
      <div className="EventStatus">
        
        <span className="ServiceStatusText">Service Status:</span>
        <ButtonTooltip title={service_message[this.state.status]} placement="top">
          <div className="EventStatusIndicator" id = "EventStatusIndicator" style={{backgroundColor: service_colors[this.state.status], boxShadow: "0px 0px 4px 2px "+service_colors[this.state.status]}}></div>
        </ButtonTooltip>
      </div>
    )
  }
}