import { createSlice } from '@reduxjs/toolkit'

const NO_GROUP = ''

const groupCodeSlice = createSlice({
    name: 'groupCode',
    initialState: NO_GROUP,
    reducers: {
        joinGroup: (state, action) => action.payload,
        leaveGroup: (state) => NO_GROUP,
    },
})

export { NO_GROUP }
export default groupCodeSlice
