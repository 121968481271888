import React, { Component } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import {MdOpenInBrowser, MdOutlineLink, MdOutlineClose, MdPerson} from 'react-icons/md'
import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { BsInfoCircle, BsRecord2 } from 'react-icons/bs'

import PlayerColours from '../../data/colours'
import classNames from "classnames";
import { connect } from 'react-redux'
import groupCode from './groupCode'
import { NO_GROUP } from './groupCode'
import trackedPlayer from './trackedPlayer'

const ShareButton = styled(Button)({
  fontFamily: "Poppins",
  fontSize: 12,
  padding: '6px 20px',
  margin: '6px',
  border: '1px solid #808180',
  color: "#d6d7d5",
  lineHeight: 1.5,
  transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
  '&:hover': {
    backgroundColor: '#434241',
    borderColor: '#a6a7a6',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#434241',
    borderColor: '#a6a7a6',
  },
  '&:focus': {
    borderColor: '#a6a7a6',
    boxShadow: '0 0 0 0.2rem rgba(166, 167, 166,.5)',
    outline: "none"
  },
  '&:disabled': {
    color: "#742624",
    borderColor: '#742624',
    outline: "none"
  },
});

const LocationButton = styled(Button)({
  fontFamily: "Poppins",
  fontSize: 12,
  padding: '6px 20px',
  margin: '6px',
  border: '1px solid #c2403c',
  color: "#c2403c",
  lineHeight: 1.5,
  transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
  '&:hover': {
    backgroundColor: '#434241',
    borderColor: '#c2403c',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#434241',
    borderColor: '#c2403c',
  },
  '&:focus': {
    borderColor: '#c2403c',
    boxShadow: '0 0 0 0.2rem rgba(194,64,60,.5)',
    outline: "none"
  },
  '&:disabled': {
    color: "#742624",
    borderColor: '#742624',
    outline: "none"
  },
});

const CssTextField = styled(TextField)({
  fontFamily: "Poppins",
  '& label.Mui-focused': {
    color: '#c2403c',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#c2403c',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: "1px solid #808180",
    },
    '&:hover fieldset': {
      borderColor: '#c2403c',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#c2403c',
    },
  },
  '& .MuiInputBase-input': {
    color: "#eeefed",
  },
  '&  .MuiInputLabel-root': {
    color: "#d6d7d5",
  }
});


function BasicTextFields(joinText, handleJoinText) {
  return (
    <Box
      component="form"
      sx={{
        '& > :not(style)': { width: '20ch', },
      }}
      noValidate
      autoComplete="off"
    >
      <CssTextField id="size-input" label="Join Code" variant="outlined" size="small" type="text"
         value={joinText}
         onChange={handleJoinText}
      />
    </Box>
  );
}

class ShareLinkButton extends Component {
  constructor(props) {
		super(props);

		this.state = {
      open: false,
		}
	}


  handleTooltipClose = () => {
    this.setState({open: false});
  };

  handleTooltipOpen = () => {
    this.setState({open: true});
  };

  buttonClick = () => {
    this.props.shareGroup()
    this.handleTooltipOpen()
  }

  render (){
    return (
      <ClickAwayListener onClickAway={this.handleTooltipClose}>
        <div>
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={this.handleTooltipClose}
            open={this.state.open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title="Share Link Copied to Clipboard"
          >
            <ShareButton variant="outlined" startIcon={<MdOutlineLink/>} onClick={this.buttonClick}>Share</ShareButton>
          </Tooltip>
        </div>
      </ClickAwayListener>
    )
  }

}


class StreamLocation extends Component {
  constructor(props) {
		super(props);

		this.state = {
      joinText: "",
		}
	}

  handleJoinText = (event) => { 
    this.setState({joinText: event.target.value})
  }

  createGroup = () => {
    //add create group logic

    this.setState({in_group: true})
  }

  joinGroup = () => {
    if (this.state.joinText) {
      this.props.joinGroup(this.state.joinText)
    }
  }

  leaveGroup = () => {
    this.props.leaveGroup(this.state.joinText)
  }

  shareGroup = () => {
    navigator.clipboard.writeText("https://newworldminimap.com/join?code="+this.props.groupCode)
  }

  trackPlayer = (id) => {
    this.props.trackPlayer(id)
  }

 render(){
  var sharelocation_content
  var player_list = []
  var player_num = 0
  for (let id in this.props.locations) {
      let { name: playerName, position: [x, y, _rotation] } = this.props.locations[id]
      var player_colour = PlayerColours[player_num]
      var tracking_icon
      var active = false
      if((this.props.TrackedPlayer === id) || ((this.props.TrackedPlayer === "") && (this.props.playerName === playerName))){
        if (this.props.pinned == true){
          active = true
          tracking_icon = <BsRecord2 style={{marginRight: "2px", marginBottom: "2px", color: "#c2403c"}}/>
        }

      } else{
        tracking_icon = <span style={{width: "16px"}}></span>
      }
      player_list.push(
        <div key={id} className={classNames("ShareLocationPlayer", {active: active})} onClick = {() => {this.trackPlayer(id)}}>
          <span className="ShareLocationPlayerSpan">{tracking_icon} <MdPerson style={{marginRight: "2px", marginBottom: "2px", color: player_colour}}/> {this.props.locations[id].playerName}</span>
          <span className="ShareLocationPlayerSpan">x: {x}, y: {y}</span>
        </div>
      )
      player_num += 1
      if (player_num > PlayerColours.length -1){
        player_num = 0
      }
  }

  if (this.props.groupCode !== NO_GROUP) {
    sharelocation_content = 
    <React.Fragment>
      <div className="ShareLocationRow">
          Group Controls
          <div className="ShareLocationInput">
            <ShareLinkButton shareGroup={this.shareGroup.bind(this)} />
            <LocationButton variant="outlined" startIcon={<MdOutlineClose/>} onClick={this.leaveGroup}>Leave</LocationButton>
          </div>
        </div>
        <div className="ShareLocationRow">
          Group Members
          <div className="ShareLocationPlayerList">
            {player_list}
          </div>
        </div>
      </React.Fragment>
  } else {
    sharelocation_content = 
    <React.Fragment>
      {/* <div className="ShareLocationInfoRow">
        <span><BsInfoCircle style={{fontSize: "14px", marginBottom: "2px"}}/> Group size is limited to 5</span><span>Location Sharing is in beta and the service may go down </span>
      </div> */}
      <div className="LocationStreamingInfo">
        Location streaming allows you to stream your location to a resource map. Click the <span style={{color: "#c2403c"}}><MdOpenInBrowser/>Location Streaming</span> Button in the New World Minimap app to enable this.
      </div>
      <div className="LocationStreamingInfo" style={{marginTop: "10px"}}>
        Alternatively, use a code to stream. You can generate a code in the Location Streaming tab of the fullscreen map in NWMM.
      </div>
      <div className="ShareLocationRow" >
          Join a Stream Code
          <div className="ShareLocationInput">
          {BasicTextFields(this.state.joinText, this.handleJoinText.bind(this))}
          <LocationButton variant="outlined" disabled={(this.state.joinText === "")} onClick={this.joinGroup}>Join</LocationButton>
          </div>
        </div>
      </React.Fragment>
  }

  return (
    <div className="ShareLocationContainer" style={{color: "#d6d7d5"}}>
      {sharelocation_content}
    </div>
  );
 }
  
}

function mapStateToProps(state) {
  const { connectedPlayers, groupCode, trackedPlayer } = state;
  return {
    locations: connectedPlayers,    
    groupCode,
    TrackedPlayer: trackedPlayer.trackedPlayerId,
    pinned: trackedPlayer.pinned,
  };
}

const mapDispatchToProps = {
  joinGroup: groupCode.actions.joinGroup,
  leaveGroup: groupCode.actions.leaveGroup,
  trackPlayer: trackedPlayer.actions.trackPlayer,
}

export default connect(mapStateToProps, mapDispatchToProps)(StreamLocation);
