import React, { Component } from 'react';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const ButtonTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#070606",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#070606",
    color: '#ABA9A6',
  },
}));

export default ButtonTooltip;
