import { createStore, combineReducers } from 'redux'
import groupCodeSlice from './features/locationSharing/groupCode'
import connectedPlayersSlice from './features/locationSharing/connectedPlayers'
import connectionStatusSlice from './features/locationSharing/connectionStatus'
import trackedPlayerSlice from './features/locationSharing/trackedPlayer'

const reducer = combineReducers({
    groupCode: groupCodeSlice.reducer,
    connectedPlayers: connectedPlayersSlice.reducer,
    connectionStatus: connectionStatusSlice.reducer,
    trackedPlayer: trackedPlayerSlice.reducer,
})

const store = createStore(reducer)

export default store
