import React from 'react';
import { Helmet } from 'react-helmet';
import { Collapse } from 'reactstrap';
import EventStatus from './components/EventStatus';

// const description_url = 'https://api.metatft.com/item_descriptions'


var cost_colours = {1:"#b5b5b5",
              2:"#14cf11",
              3:"#0e2ae3",
              4:"#c211cf",
              5:"#b8a31c" }

class OverwolfHelp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      "1": false,
      "2": false,
      "3": false,
    }
  }

  toggle(num){
    const current_state = {...this.state}
    this.setState({[num]: !current_state[num]})
  }


  render() {
    
    return (
      <div>
      <Helmet>
        <title>Overwolf Help Page</title>
      </Helmet>
      <div className="App">
        <div className="container" style={{display: "flex", flex: "1 1 auto", flexDirection: "column", justifyContent: "flex-start"}}>
        <div className ="Title">
          <img className="Logo" src="nwmm_logo2.png" alt="NWMM - New World Minimap logo"></img>
          <b>NWMM Help Page</b>
        </div>
          <p style = {{textAlign: "left", marginTop: "20px", color: "cfcfcf", width: "100%", lineHeight: "1.8",}}>
            <EventStatus></EventStatus>
          </p>
          <p style = {{textAlign: "left", marginTop: "20px", color: "cfcfcf", width: "100%", lineHeight: "1.8"}}>
            <div className="HelpTitle">
              Something isn't working
            </div>
            <ul>
              <li className="FAQheader" onClick={() => {this.toggle("1")}}>Location not updating on minimap or fullscreen map. Map is stuck in a city, not where you are.</li>
              <Collapse isOpen={this.state["1"]}>
                This is likely an issue with the Overwolf API. The current fix is:
                <ul>
                  <li>Quit both Overwolf and New World (you will need to quit Overwolf from the system tray or Task Manager)</li>
                  <li>Start Overwolf first</li>
                  <li>Launch New World</li>
                </ul>
                Your location should hopefully update now. If this doesn't work - check the service status isn't "red" as that means game events are down. They should come back up in 24 hours.
              </Collapse>
              <li className="FAQheader" onClick={() => {this.toggle("2")}}>The App won't start in game, and/or you can't reassign hotkeys</li>
              <Collapse isOpen={this.state["2"]}>
                <p>Make sure to restart Overwolf to ensure you're on the most up to date version. Older versions may not recognise New World as a game. If it still doesn't work after a restart you may need to reinstall Overwolf completely </p>
                <p>If that doesn't work, The game may be installed in an inaccessible directory, try following the steps <a className="Link" href="https://support.overwolf.com/en/support/solutions/articles/9000202391-overlay-troubleshooting">here</a></p>
              </Collapse>
              <li className="FAQheader" onClick={() => {this.toggle("3")}}>Location Streaming status red, "cannot communicate with NWWM" message</li>
                <Collapse isOpen={this.state["3"]}>
                This is caused by the website not being able to find the addon's websocket server. To fix, follow these steps:
                <ul>
                  <li>Hit windows key, type 'notepad', right click and choose 'run as administrator'</li>
                  <li>Open C:\Windows\System32\drivers\etc\hosts</li>
                  <li>Add this line at the end:
                    <p>127.0.0.1 localhost.newworldminimap.com</p></li>
                  <li>Save and Exit, then refresh the browser tab</li>
                </ul>
                If this doesn't work, try running
                "telnet localhost.newworldminimap.com 42224"
                in the cmd console, and seeing if there are any error messages. You can also choose to stream with a stream code instead (this is available in the fullscreen map, in the NWMM app).
              </Collapse>
              <li className="FAQheader" onClick={() => {this.toggle("4")}}>[Monitors with DPI scaling] The minimap appears too small, you see scrollbars and/or can't interact with settings</li>
              <Collapse isOpen={this.state["4"]}>
              Please follow the steps outlined on <a className="Link" href="https://support.overwolf.com/en/support/solutions/articles/9000176964-scaling-overwolf-apps-to-appear-properly-with-a-high-dpi-setting">this link</a> 
              </Collapse>
            </ul>
          </p>
          <p style = {{textAlign: "left", marginTop: "20px", color: "cfcfcf", width: "100%", lineHeight: "1.8"}}>
            <div className="HelpTitle">
              FAQ
            </div>
            <ul>
              <li className="FAQheader" onClick={() => {this.toggle("101")}}>Will this get me banned?</li>
              <Collapse isOpen={this.state["101"]}>
                <p>Our Minimap is now fully ToS compliant, and will not result in bans. The team at Overwolf is regularly in contact with Amazon to ensure the app meets their requirements. If Amazon change their mind, they have stated that they will give at least 24 hours notice before issuing any bans. </p>
                <p>More info in Amazon's dev post on Minimaps available <a className="Link" href="https://forums.newworld.com/t/dev-blog-mini-map/535888">here</a> </p>
              </Collapse>
              <li className="FAQheader" onClick={() => {this.toggle("102")}}>Why can't I see Nodes/Why aren't the filters working?</li>
              <Collapse isOpen={this.state["102"]}>
                <p>In order to meet <a className="Link" href="https://forums.newworld.com/t/dev-blog-mini-map/535888">Amazon's ToS</a>, we have had to remove nodes from the minimap. Filters will be reinstated once compass data is available, however for now you need to stream your location to a separate browser map.</p>              
              </Collapse>
              <li className="FAQheader" onClick={() => {this.toggle("103")}}>How do I stream my location to somewhere else?</li>
              <Collapse isOpen={this.state["103"]}>
                <p><b>Streaming to another device:</b>
                <ul>
                  <li>Open the fullscreen map (Default hotkey Alt+N)</li>
                  <li>Open the Stream Location tab</li>
                  <li>Select "Create a Stream Code". This should open your default browser connected with that code</li>
                  <li>You can send that URL to another device and see your player there. You can also share this with other players to share your location as a group</li>
                </ul>
                </p>
                <p><b>Streaming to NewWorld-Map</b>
                <ul>
                  <li>Open New World and have NWMM running</li>
                  <li>Head to  <a className="Link" href="https://www.newworld-map.com/#/">https://www.newworld-map.com/#/</a></li>
                  <li>Toggle the location tracking on</li>
                  <img src="newworldmap_nwmm.png" alt="NewWorld-Map Location Sharing" ></img>
                </ul>
                </p>
                <p><b>Streaming to MapGenie</b>
                <ul>
                  <li>Open New World and have NWMM running</li>
                  <li>Head to  <a className="Link" href="https://mapgenie.io/new-world/maps/aeternum">https://mapgenie.io/new-world/maps/aeternum</a></li>
                  <li>Toggle the location tracking on</li>
                  <img src="mapgenie_nwmm.png" alt="MapGenie Location Sharing"></img>
                </ul>
                </p>
                Note: NewWorld-Map and MapGenie streaming will only work on the same PC that is running NWMM & New World
              </Collapse>
              <li className="FAQheader" onClick={() => {this.toggle("104")}}>How do I make the map follow my player?</li>
              <Collapse isOpen={this.state["104"]}>
                <p>Make sure the follow player button is selected (it gets deselected when panning on the map). You can also change the follow frequency by pressing the settings cog</p>              
                <img src="follow_player.png" alt="Follow Player"></img>
              </Collapse>
              
            </ul>

          </p>
          <p style = {{textAlign: "left", marginTop: "20px", color: "cfcfcf", width: "100%"}}>
            If there are any unanswered questions please reach out via our <a href = "https://discord.gg/VhH7mu2SXt" style={{color:"#c2403c"}}>Discord</a>
          </p>
        </div>
      </div>
    </div>
    );
  }
}

export default  OverwolfHelp;