import './App.css';
import React, { Component } from 'react';
import Minimap from './OLMinimap'
import AdBox from './AdBox.js'
import Filters from './components/Filters'
import Nodes from './data/nodes.json'
import Collapse from '@mui/material/Collapse';
import classNames from "classnames";
import Scrollbars from 'react-custom-scrollbars-2';
import {MdOutlineChevronRight, MdOutlineExpandMore, MdOutlineWarningAmber} from 'react-icons/md'
import ShareLocation from './features/locationSharing/ShareLocation'

const dropdown_icons = {}
dropdown_icons[true] = <MdOutlineExpandMore className="SidebarRowIcon"/>
dropdown_icons[false] = <MdOutlineChevronRight className="SidebarRowIcon"/>

class Fullscreen extends Component {
  constructor(props) {
		super(props);

		this.state = {
      open: 1,
      enabled: []
		}
	}

  toggle_open = (num) => {
    if(this.state.open === num){
      this.setState({open: 0})
    } else {
      this.setState({open: num})
    }
  }

  updateEnabled = (new_enabled) => {
    this.setState({enabled: new_enabled})
  }

 render(){
  return (
    <div className="App">
        <div className="MainContainer">
          <div className="Sidebar">
            <div className="SidebarTop">
              <div className={classNames("SidebarRow", {active: (this.state.open === 1)})} onClick={() => {this.toggle_open(1)}}>
                Filters
                {dropdown_icons[(this.state.open === 1)]}
              </div>    
              
              <Scrollbars autoHeight autoHeightMin="1px" autoHeightMax="100%">
                <Collapse in={(this.state.open === 1)}>
                    <Filters Nodes={Nodes} updateEnabled={this.updateEnabled.bind(this)} enabled={this.state.enabled}/>
                </Collapse>
              </Scrollbars>
              {/* <div className={classNames("SidebarRow", {active: (this.state.open === 2)})} onClick={() => {this.toggle_open(2)}}>
                Find a Resource
              </div>
              <Scrollbars autoHeight autoHeightMin="1px" autoHeightMax="100%">
                <Collapse in={(this.state.open === 2)}>
                </Collapse>
              </Scrollbars> */}
              {/* <div className={classNames("SidebarRow", {active: (this.state.open === 3)})}>
              onClick={() => {this.toggle_open(2)}}
                Party Members 
                {dropdown_icons[(this.state.open === 2)]}
                <span className="ShareLocationInfoRow"> Coming soon!</span>
              </div> */}
              <div className={classNames("SidebarRow", {active: (this.state.open === 2)})} onClick={() => {this.toggle_open(2)}}>
                Stream Location
                {dropdown_icons[(this.state.open === 2)]}
              </div>
              <Scrollbars autoHeight autoHeightMin="1px" autoHeightMax="100%">
                <Collapse in={(this.state.open === 2)}>
                  <ShareLocation />
                </Collapse>
              </Scrollbars>
            </div>
            <AdBox/>
          </div>
          <div className="FullscreenMapContainer">
            <Minimap minimap = {false} enabled = {this.state.enabled} updateEnabled={this.updateEnabled.bind(this)}/>
          </div>
        </div>
    </div>
  );
 }
  
}

export default Fullscreen;
