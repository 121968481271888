import { createSlice } from '@reduxjs/toolkit'

const connectionStatusSlice = createSlice({
    name: 'connectionStatus',
    initialState: 3,
    reducers: {
        setStatus: (state, action) => action.payload,
    },
})

export default connectionStatusSlice
