import './App.css';
import React, { Component } from 'react';
import Minimap from './OLMinimap.js'
import { Link } from 'react-router-dom'

class Main extends Component {
  constructor(props) {
		super(props);

		this.state = {
      
		}
    this.createdAds = {} 
	}

  componentDidMount = () => {
    this.createAd(0)
  }

  createAd = (counter) => {
    if (window['nitroAds'] !== "undefined") {
      console.log("Creating ads")
      if (!("MetaTFTVideo" in this.createdAds)){
        window['nitroAds'].createAd('MetaTFTVideo', {
          "format": "video-nc",
          "video": {
            "float": "always",
            "mobile": "compact",
            "initialDelay": 3,
            "hidePlaylist": true
          }
        });
        this.createdAds['MetaTFTVideo'] = true
      }
    } else {
      counter +=1
      if (counter < 20){
        setTimeout(() => {
          this.createAd(counter)
        }, 1000);
      } else {
        console.log("20 reattempts, couldn't load add")
      }
    }
  }


 render(){
  return (
    <div className="App">
      <div className="container" style={{display: "flex", flex: "1 1 auto", flexDirection: "column", justifyContent: "space-between"}}>
        <div className ="Title">
          <img className="Logo" src="nwmm_logo2.png" alt="NWMM - New World Minimap logo"></img>
          <b>New World MiniMap</b>
        </div>
        {/* <div className ="Row">
          The New World Minimap is a project from two friends that were always getting disoriented in New World towns. We teamed up and developed a minimap to stop that happening and make resource gathering while questing more efficient, and wanted to share the outcome with the New World community. 
        </div> */}
        <div className ="Row">
          The New World Minimap is a project from two friends that were always getting disoriented in New World towns. We teamed up and developed a minimap to stop that happening and wanted to share the outcome with the New World community. 
        </div>
        <div className ="Row" style={{color: "#eeefed"}}>
          <span>Try the live demo below, or see the <Link className="Link" to="/map" style={{display: "inline-block"}}>fullscreen map</Link></span>
        </div>
        <div className ="Row">
          <Minimap minimap="true"/>
        </div>
        <div className ="Row" style={{textAlign: "left"}}>
          <b style={{color: "#eeefed"}}>Core Features</b>
          <ul>
            <li>
              Auto zoom when entering a city
            </li>
            <li>
              All Town stations marked
            </li>
            {/* <li>
              Community-sourced data used with permission from <a className = "Link" href="https://www.newworld-map.com/#/">newworld-map.com</a>
            </li> */}
            <li>
              <a className = "Link" href="https://forums.newworld.com/t/dev-blog-mini-map/535888">ToS Compliant</a> and won't get you banned
            </li>
            <li>
              Stream Location to external site
            </li>
          </ul>
        </div>
        <div className ="Title">
          <b>Download New World Minimap Now!</b>
          <a href="https://www.overwolf.com/app/New_World_MiniMap-NWMM_-_New_World_MiniMap"><img className="DownloadButton" src="getitionow.png" alt="Get NWMM on Overwolf"></img></a>
        </div>
      </div>
      <div className="footer">
        <div className="container" style={{fontSize:"12px", padding: "2px"}}>
          &copy; {new Date().getFullYear()} NewWorldMiniMap.com New World MiniMap is not affiliated with New World or Amazon Game Studios in any way. View our <Link className = "Link" to="privacy">privacy policay</Link>
        </div>
      </div>
      <div id="MetaTFTVideo"></div>
    </div>
  );
 }
  
}

export default Main;
