import './App.css';
import React, { Component } from 'react';

class NoMatch extends Component {
  constructor(props) {
		super(props);

		this.state = {
		}
	}

 render(){
  return (
    <div className="App">
      <div className="container" style={{display: "flex", flex: "1 1 auto", flexDirection: "column", justifyContent: "space-between"}}>
        <div className ="Title">
          <img className="Logo" src="nwmm_logo2.png" alt="NWMM - New World Minimap logo"></img>
          <b>New World MiniMap</b>
        </div>
        <div className ="Row">
          404 page not found
        </div>
    </div>
  </div>
  );
 }
  
}

export default NoMatch;
